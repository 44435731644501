import Option from "./components/option";
import Questions from "./components/questions";
import Questionform from "./components/questionsform";
import Tables from "./components/tables";
import Calculator from "./components/calculator";
import Keyboard from "./components/keyboard";
import Todo from "./components/todo";
import Data from "./data.json";
import Test from "./components/test";
import Header from "./components/header";
// import "./style.css";
import { HashRouter, Route, Routes } from "react-router-dom";
function App() {
  return (
    <div className="container">
      <HashRouter>
        <Header />
        <Routes>
          <Route path="/Calculator" Component={Calculator} />
          <Route path="/Keyboard" Component={Keyboard} />
          <Route path="/Todo" Component={Todo} />
          <Route path="/Questions" Component={Questions} />
          <Route path="/Tables" Component={Tables} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
