import Option from "./option";

const Questions = ({ Data }) => {
  return (
    <div>
      {Data.map((obj) => {
        return (
          <div>
            {obj.question}
            <div>
              {obj.options.map((a) => {
                return (
                  <Option key={a.id} id={a.id} name={a.name} label={a.label} />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Questions;
