import { useState } from "react";

const Tables = () => {
  const [number, setnumber] = useState(0);

  const onchange = (e) => {
    setnumber(e.target.value);
  };

  const list = Array.from({ length: 10 });

  return (
    <div className="container-div">
      <p> Enter a number:</p>
      {/* <p>{JSON.stringify(list)}</p> */}
      <input name="numbers" value={number} onChange={onchange} />

      {isNaN(parseInt(number)) ? (
        <></>
      ) : (
        list.map((_, i) => (
          <div key={i}>
            {number} x {i + 1} = {parseInt(number) * (i + 1)}
          </div>
        ))
      )}
    </div>
  );
};

export default Tables;
