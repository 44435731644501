import { useState } from "react";
import { TbSpace } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const Keyboard = () => {
  const [value, setValue] = useState("");
  const list = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const arrayValue = (e) => {
    console.log(e.target.innerText);
    setValue(value + e.target.innerText);
  };
  const addSpace = (e) => {
    setValue(value + " ");
  };
  const clearText = (e) => {
    setValue("");
  };
  const backSpace = (e) => {
    //console.log(value);
    const length = value.length;
    //console.log(length);
    const newValue = value.slice(0, length - 1);
    setValue(newValue);
  };
  return (
    <>
      <div className="row">
        <div className="col-6 offset-3">
          <div className="input-group">
            <input type="text" className="form-control" value={value} />
          </div>
        </div>
      </div>

      <div className="row d-flex justify-content-center">
        <div className="col-6">
          {list.map((item) => (
            <button
              className="btn btn-primary mx-3 my-3"
              onClick={arrayValue}
              key={item}
            >
              {item}
            </button>
          ))}
          <div className="row d-flex justify-content-center">
            <div className="col-6">
              <button className="btn btn-primary mx-3 my-3" onClick={addSpace}>
                <TbSpace />
              </button>
              <button className="btn btn-primary mx-3 my-3" onClick={clearText}>
                <RiDeleteBin6Line />
              </button>
              <button className="btn btn-primary" onClick={backSpace}>
                <MdOutlineKeyboardBackspace />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Keyboard;
