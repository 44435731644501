import { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

const Todo = () => {
  const [listvalue, setlistvalue] = useState("");
  const [list, setlistItem] = useState([]);

  const inputValue = (e) => {
    setlistvalue(e.target.value);
    // console.log(e.target.value);
  };
  const addList = (e) => {
    setlistItem([...list, listvalue]);
    setlistvalue("");
  };
  const deleteList = (index) => {
    let reduceList = [...list];
    console.log("before splice" + reduceList);
    reduceList.splice(index, 1);
    console.log("After splice" + reduceList);
    setlistItem(reduceList);
  };
  return (
    <div className="container-div">
      <input type="text" value={listvalue} onChange={inputValue} />
      <button onClick={addList}>+</button>
      <div>
        <h3>List</h3>
        <ul>
          {list.map((item, index) => (
            <li>
              {item}{" "}
              <button onClick={() => deleteList(index)}>
                <RiDeleteBin6Line />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Todo;
