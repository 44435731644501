import { useState } from "react";

const Calculator = () => {
  const [firstnumber, setnumber] = useState("");
  const [operator, setoperator] = useState("+");
  const [secondnumber, setsecondnumber] = useState("");
  const [result, setResult] = useState();

  const onchange = (e) => {
    setnumber(e.target.value);
  };

  const onselect = (e) => {
    setoperator(e.target.value);
  };

  const onchangesecond = (e) => {
    setsecondnumber(e.target.value);
  };
  const onButtonclick = () => {
    if (isNaN(firstnumber) === true || isNaN(secondnumber) === true) {
      setResult("Enter a valid number");
    } else {
      if (operator === "+") {
        setResult(parseInt(firstnumber) + parseInt(secondnumber));
      } else if (operator === "-") {
        setResult(parseInt(firstnumber) - parseInt(secondnumber));
      } else if (operator === "x") {
        setResult(parseInt(firstnumber) * parseInt(secondnumber));
      } else if (operator === "/") {
        setResult(parseInt(firstnumber) / parseInt(secondnumber));
      }
    }
  };

  return (
    <div className="container-div ">
      <p>Calculator:</p>
      <input
        className="input-name"
        name="firstnumber"
        value={firstnumber}
        onChange={onchange}
      />
      <select onChange={onselect} value={operator}>
        <option value="+">+</option>
        <option value="-">-</option>
        <option value="x">x</option>
        <option value="/">/</option>
      </select>
      <input
        name="secondnumber"
        value={secondnumber}
        onChange={onchangesecond}
      />
      <button className="btn" onClick={onButtonclick}>
        Calculate
      </button>
      <div> {result} </div>
    </div>
  );
};
export default Calculator;
