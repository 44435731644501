import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="navbar navbar-expand bg-body-tertiary mb-5">
      <ol className="navbar-nav">
        <li className="nav-item mx-3">
          <Link
            to={"/Calculator"}
            className="link-underline link-underline-opacity-0"
          >
            Calculate
          </Link>
        </li>
        <li className="nav-item mx-3">
          <Link
            to={"/Todo"}
            className="link-underline link-underline-opacity-0"
          >
            Todo
          </Link>
        </li>
        <li className="nav-item mx-3">
          <Link
            to={"/Keyboard"}
            className="link-underline link-underline-opacity-0"
          >
            Keyboard
          </Link>
        </li>
        <li className="nav-item mx-3">
          <Link
            to={"/Tables"}
            className="link-underline link-underline-opacity-0"
          >
            Tables
          </Link>
        </li>
      </ol>
    </div>
  );
};
export default Header;
